define("ember-svg-jar/inlined/checked-circle-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M7 14A7 7 0 107 0a7 7 0 000 14z\" fill=\"#00C875\"/><path d=\"M4.38 7.57l1.07 1.26c.08.1.22.17.36.17a.5.5 0 00.38-.15l3.43-3.6\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h14v14H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "14",
      "height": "14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});