define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "cl.connectWallet": "Connect Wallet",
    "cl.loading": "Loading...",
    "cl.logoTitle": "<span>Maneki</span>-Neko",
    "cl.nftMint": "NFT Mint",
    "cl.staking": "Staking",
    "cl.title": "Maneki-Neko - The Lucky Cat",
    "mint": "Mint <span>NFT</span>",
    "mint.benefits.multiplier": "Earn a 1.2x ETH rewards multiplier",
    "mint.benefits.nekoVault": "The Neko NFT is your whitelist to receive the NekoVault first",
    "mint.connectWallet": "Connect Wallet",
    "mint.hint": "The Neko NFT offers 1.2x multiplier for staking rewards. Staking your tokens along with your Neko NFT amplifies the ETH rewards you'll receive, generating significant passive income.",
    "mint.mint": "Mint NFT",
    "mint.minted": "NFTS Minted",
    "mint.public": "Public Mint is Open!",
    "mint.total": "NFT Collection",
    "staking": "Stake <span>NEKO</span>",
    "staking.approve": "Approve Tokens",
    "staking.auditedBy": "Contract audited by",
    "staking.claiming": "Rewards",
    "staking.connectWallet": "Connect Wallet",
    "staking.eth.available": "Available ETH",
    "staking.eth.claim": "Claim ETH",
    "staking.help": "Problems staking NEKO?",
    "staking.help.hint": "Checkout our FAQ's section for common questions and solutions",
    "staking.hint": "The spirit of DeFi is strong in Neko. Revenue from NekoMask swap fees and NekoGuard services are infused into the Neko staking pool. These ETH rewards can be claimed by Neko holders who have chosen to stake their tokens.",
    "staking.nfts": "NFT's",
    "staking.nfts.approve": "Approve NFT",
    "staking.nfts.available": "Available NFT's",
    "staking.nfts.boost": "NFT Booster",
    "staking.nfts.boost.hint": "Stake a NEKO nft and multiply your ETH rewards",
    "staking.nfts.stake": "Stake NFT",
    "staking.nfts.stake.select": "Select an NFT to stake",
    "staking.nfts.staked": "Staked NFT's",
    "staking.nfts.stakedTime": "NFT {unlocked, plural, =1 {unlocked} other {will unlock}}",
    "staking.nfts.unstake": "Unstake NFT",
    "staking.rewards": "Receive ETH Rewards",
    "staking.shares": "Your Shares",
    "staking.shares.total": "Total Shares",
    "staking.shares.tvl": "Total Value Locked (TVL)",
    "staking.stake": "Stake Tokens",
    "staking.stake.max": "MAX",
    "staking.staking": "Stake",
    "staking.tokensAvailable": "Tokens Available",
    "staking.tokensAvailable.value": "{number} NEKO",
    "staking.unlock": "Unlock Tokens",
    "staking.unlock.period": "Shares {unlocked, plural, =1 {were unlocked} other {will unlock}}",
    "staking.unstake": "Unstake Tokens",
    "staking.unstaking": "Unstake"
  }]];
  _exports.default = _default;
});